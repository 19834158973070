@import './css/icons';
@import './css/text';
@import './css/buttons';
@import './css/content';
@import './css/button-loader';

:root {
  --swal-modal-width: 478px;
}

.swal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0; /* Remove gap between inline-block elements */
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  &::before {
    content: ' ';
    display: inline-block;
    vertical-align: middle; /* vertical alignment of the inline element */
    height: 100%;
  }

  &--show-modal {
    opacity: 1;
    pointer-events: auto;

    & .swal-modal {
      opacity: 1;
      pointer-events: auto;
      box-sizing: border-box;
      animation: showSweetAlert 0.3s;
      will-change: transform;
    }
  }
}

.swal-modal {
  width: var(--swal-modal-width);
  opacity: 0;
  pointer-events: none;
  background-color: white;
  text-align: center;
  border-radius: 5px;

  position: static;
  margin: 20px auto;
  display: inline-block;
  vertical-align: middle;

  transform: scale(1);
  transform-origin: 50% 50%;
  z-index: 10001;
  transition: transform 0.3s, opacity 0.2s;

  @media all and (max-width: 500px) {
    width: calc(100% - 20px);
  }
}

@keyframes showSweetAlert {
  0% {
    transform: scale(1);
  }
  1% {
    transform: scale(0.5);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
