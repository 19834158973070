.switch-button-label {
    float: left;

    font-size: 10pt;
    cursor: pointer;
}

.switch-button-label.off {
    color: #adadad;
}

.switch-button-label.on {
    color: #0088CC;
}

.switch-button-background {
    float: left;
    position: relative;

    background: #ccc;
    border: 1px solid #aaa;

    margin: 1px 10px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    cursor: pointer;
}

.switch-button-button {
    position: absolute;

    left: -1px;
    top : -1px;

    background: #FAFAFA;
    border: 1px solid #aaa;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
